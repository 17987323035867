var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "mx": "auto",
      "h": ['calc(100vh - 64px)', 'auto'],
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": "16px",
      "p": ['1rem', '1.5rem'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('c-box', {
    attrs: {
      "w": ['100%', '600px'],
      "mx": "auto",
      "text-align": "center",
      "d": "flex",
      "flex-direction": "column",
      "align-items": "center",
      "justify-content": "center",
      "h": "100%"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "vue.500",
      "size": "xl"
    }
  }), _c('c-text', {
    attrs: {
      "mt": "8px"
    }
  }, [_vm._v(" Sedang Memproses... ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }