<template>
  <c-box
    w="100%"
    mx="auto"
    :h="['calc(100vh - 64px)', 'auto']"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    border-radius="16px"
    :p="['1rem', '1.5rem']"
    :min-height="['unset', '74vh']"
  >
    <c-box
      :w="['100%', '600px']"
      mx="auto"
      text-align="center"
      d="flex"
      flex-direction="column"
      align-items="center"
      justify-content="center"
      h="100%"
    >
      <c-spinner
        thickness="4px"
        speed="0.65s"
        empty-color="green.200"
        color="vue.500"
        size="xl"
      />
      <c-text mt="8px">
        Sedang Memproses...
      </c-text>
    </c-box>
  </c-box>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'ReorderCheckout',
  data() {
    return {
      productServiceId: this.$route.query['product_service_id'],
    }
  },
  created() {
    if (this.productServiceId) {
      this.getProductServiceId(this.productServiceId)
        .then((res) => {
          this.handleBuyNow(res.data, res.data.product.photoUrl)
        })
        .catch((err) => {
          this.$toast({
            status: 'error',
            title: 'Error',
            description: err?.data?.message,
            duration: 3000,
          })
          this.$router.push('/')
        })
    } else {
      this.$router.push('/')
    }
  },
  methods: {
    ...mapActions({
      getProductServiceId: 'general/getProductServiceId',
    }),
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
    }),
    handleBuyNow(item, photo) {
      this.setSelectedCart({
        quantity: 1,
        productService: {
          ...item,
          product: {
            photoUrl: photo,
          },
        },
        _isBuyNow: true,
      })
      this.$router.push({ name: 'client.cart.check-order' })
    },
  },
}
</script>
